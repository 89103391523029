
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Item } from '../store/models/ItemModel';
import { DieTemplate } from '../store/models/DieModel';
@Component
export default class extends Vue {
  /* Properties */
  @Prop({ required: true }) item!: DieTemplate;
  @Prop() doesOverlay!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  svgPreview: boolean = false;
  /* Methods */
  svgPreviewClick() {
    this.svgPreview = true;
  }
  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
}
